// dropdown
.dropdown-toggle {
    &:after {
        display: none;
    }
}

.shipment-card {
    color: #fff;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #fff;
    }
    a {
        color: #fff;
    }
    .card {
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
        box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
        border: none;
        margin-bottom: 10px;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        .card-block {
            padding: 15px;
        }
    }
    i {
        font-size: 26px;
    }
}

.shipment-list-table {
    border-collapse: separate;
    border-spacing: 0 12px;
    tr {
        background-color: $card-bg;
    }
}

.bg-c-cloud {
    background: #6ca7eb;
}

.bg-c-blue {
    background: #0163d2;
}

.bg-c-green {
    background: #0f7c1d;
}

.bg-c-pink {
    background: #e10875
}

.bg-c-red {
    background: #f10b31;
}

.bg-c-purple {
    background: #5c08bd
}

.bg-c-yellow {
    background: #c5c20c
}

.f-left {
    float: left;
}

.f-right {
    float: right;
}

.bg-incoming-wh {
    background: #f10b31;
}

.bg-manifest {
    background: #d2cf09;
}

.bg-departure {
    background: #6ca7eb;
}

.bg-arrival {
    background: #4099ff;
}

.bg-delivery {
    background: #5c08bd;
}

.bg-received {
    background: #0acb0a;
}

.avatar-sm {
    height: auto;
    width: 3em;
}