/* Importing Bootstrap SCSS file. */

@import '~bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&display=swap');

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

/* You can add global styles to this file, and also import other style files */

.modal-holder {
    animation-name: example;
    animation-duration: 0.3s;
}

@keyframes example {
    0% {
        transform: scale(0.5)
    }
    100% {
        transform: scale(1)
    }
}

.carousel-item {
    transition: opacity 0.7s ease !important;
    position: absolute !important;
    display: block !important;
    opacity: 0;
}

.carousel-item.active {
    position: relative !important;
    opacity: 1;
}

.cursor-pointer {
    cursor: pointer !important;
}

.pointer {
    cursor: pointer !important;
}

.btn-danger {
    background-color: #d00808 !important;
}

.btn-info {
    background-color: #0529d1 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0529d1 !important;
}